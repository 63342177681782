<template>
  <div class="loginbox">
    <div class="back-icon">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
    </div>
    <div class="loginBody-image">
      <van-image
        width="4rem"
        height="4rem"
        :src="require('../../assets/img/login.png')"
      />
    </div>
    <div class="loginBody-found">
      <van-tabs line-width="0px" line-height="0px" v-model="form.type">
        <!-- <van-tab :title="$t('register.phonereg')">
          <van-row>
            <van-form ref="phoneform">
              <van-cell-group>
                <van-field
                  v-model="form.username"
                  name="username"
                  clearable
                  :placeholder="$t('register.enterphone')"
                  :rules="phonerules"
                >
                  <template #label>
                    <van-dropdown-menu active-color="#1989fa">
                      <van-dropdown-item v-model="lang" :options="option" />
                    </van-dropdown-menu>
                  </template>
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="shouji"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  name="password"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="passrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.repassword"
                  :type="type"
                  name="repassword"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="repassrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
              </van-cell-group>
            </van-form>
          </van-row>
        </van-tab>
        <van-tab :title="$t('register.emailreg')">
          <van-row>
            <van-form ref="emailform">
              <van-cell-group>
                <van-field
                  v-model="form.username"
                  name="username"
                  clearable
                  :placeholder="$t('register.enteremail')"
                  :rules="emailrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="youxiang"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  name="password"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="passrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.repassword"
                  :type="type"
                  name="repassword"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="repassrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
              </van-cell-group>
            </van-form>
          </van-row>
        </van-tab> -->
        <van-tab>
          <!-- <van-tab :title=" $t('register.yonghuming')"> -->
          <van-row>
            <van-form ref="nameform">
              <van-cell-group>
                <van-field
                  v-model="form.email"
                  name="username"
                  clearable
                  :placeholder="$t('login.username')"
                  :rules="emailrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="yonghuming"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.username"
                  name="username"
                  clearable
                  :placeholder="$t('register.enterphone')"
                  :rules="usernamerules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="shouji"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  name="password"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="passrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.repassword"
                  :type="type"
                  name="repassword"
                  :right-icon="eye"
                  :placeholder="$t('register.enterpassword')"
                  @click-right-icon="openeye"
                  :rules="repassrules"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="mima"
                    />
                  </template>
                </van-field>
                <!--van-field
                  v-model="form.code"
                  :placeholder="$t('login.yaoqingma')"
                >
                  <template #left-icon>
                   <van-icon name="friends-o" />
                  </template>
                </van-field-->
              </van-cell-group>
            </van-form>
          </van-row>
        </van-tab>
      </van-tabs>
      <van-button
        class="mybtn"
        :loading="loading"
        :loading-text="$t('register.registering')"
        @click="register"
        >{{ $t('register.quickreg') }}</van-button
      >
    </div>
    <!-- <van-row class="agreement">
      <van-checkbox v-model="checked" shape="square"
        >{{ $t('register.consent') }}
      </van-checkbox>
      <span style="color:#004ea3" @click="agreement">
        {{ $t('register.agreement') }}
      </span>
    </van-row> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: '86',
      option: [],
      form: {
        type: 2,
        username: '',
        password: '',
        code: '',
        repassword: ''
      },
      type: 'password',
      loading: false,
      checked: true,
      eye: 'closed-eye',
      // 手机号验证
      phonerules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^[0-9]*$/.test(value)
          },
          message: '请填写正确的手机号',
          trigger: 'onBlur'
        }
      ],
      // 用户名验证
      usernamerules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          validator: value => {
            return /^([a-zA-Z]|[0-9])*$/.test(value)
          },
          message: this.$t('register.yhmgs'),
          trigger: 'onBlur'
        }
      ],
      // 邮箱验证
      emailrules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        },
        {
          // validator: value => {
          //   return /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
          //     value
          //   )
          // },
          message: this.$t('register.emailformat'),
          trigger: 'onBlur'
        }
      ],
      // 密码验证
      passrules: [
        {
          required: true,
          message: this.$t('register.required'),
          trigger: 'onBlur'
        }
      ],
      // 重复密码
      repassrules: [
        {
          validator: value => {
            return this.form.password === value
          },
          message: this.$t('register.eqpass'),
          trigger: 'onBlur'
        }
      ]
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/index/getcode')
      if (data) {
        if (data.code === 200) {
          this.option = data.data
        }
      }
    },
    // 查看密码
    openeye(event) {
      if (this.type === 'password') {
        this.eye = 'eye'
        this.type = 'text'
      } else {
        this.eye = 'closed-eye'
        this.type = 'password'
      }
    },
    // 快速注册
    register() {
      this.form.type = 2
      // 服务条款
      if (!this.checked) {
        this.$toast.fail(this.$t('register.readagreement'))
        return false
      }
      if (this.form.type === 0) {
        this.$refs.phoneform
          .validate()
          .then(async () => {
            //  验证通过
            this.loading = true
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      } else if (this.form.type === 2) {
        this.$refs.nameform
          .validate()
          .then(async () => {
            this.loading = true
            console.log(this.form)
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      } else {
        this.$refs.emailform
          .validate()
          .then(async () => {
            this.loading = true
            const { data } = await this.$http.post(
              '/home/index/register',
              this.form
            )
            if (data) {
              if (data.code === 200) {
                this.$toast.success(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
                this.$router.push('/login')
              } else {
                this.$toast.fail(
                  this.getlang(data.msg, localStorage.getItem('lang'))
                )
              }
            }
            this.loading = false
          })
          .catch(() => {
            // 验证失败
            return false
          })
      }
    },
    // 服务条款
    agreement() {
      this.$router.push({ path: '/terms' })
    },
    // 密码一致校验
    checkpass() {
      return this.form.password === this.form.repassword
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell {
  background-color: #ffff;
}
/deep/ .van-tabs__nav {
  background-color: #ffff;
}

/deep/ .van-field__control {
  color: #097551;
}
.loginbox {
  height: 100%;
  width: 100%;
  background: url('../../assets/img/login-bg.png'); //注册页背景
  background-size: cover;
  position: relative;
  padding: 2.75rem 2rem 0 2rem;
  .back-icon {
    position: fixed;
    left: 4%;
    top: 2%;
    .van-icon {
      color: #004ea3; //后退键
    }
  }

  .loginBody-image {
    margin-top: 25%;
    height: 20%;
    z-index: 1;
  }
  .loginBody-found {
    margin-top: 10%;
    margin-left: 5%;
    width: 90%;
    padding-bottom: 3%;
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1.5rem;
      background: transparent !important;
    }
    /deep/.van-tab {
      font-size: 1rem;
    }
    /deep/.van-tab--active {
      color: #004ea3;
      font-weight: 500;
    }
    .van-field {
      padding: 20px 0 10px 0;
      font-size: 1rem;
    }
    /deep/.van-field__label {
      width: auto;
    }
    /deep/ .van-icon,
    /deep/.van-field__right-icon {
      color: #004ea3;
      font-size: 1.2rem;
    }
    .van-tab {
      font-size: 1rem;
    }
    .van-tab--active {
      color: #004ea3;
    }
    // /deep/.van-tab__text--ellipsis {
    //   color: #004ea3;//选项字体
    // }
    .van-icon-clear {
      color: #b3b3b3;
      font-size: 1rem;
    }
    .van-field__control {
      padding-left: 5px;
    }

    .mybtn {
      margin-top: 18%;
      width: 60%;
      height: 2.66667rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #0055ff; //按钮
      border-radius: 1.6rem;
      border: none;
    }
  }

  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .agreement {
    margin-top: 15%;
    padding-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    /deep/.van-icon {
      height: 1rem;
      width: 1rem;
      font-size: 0.66667rem;
      color: #fff !important;
      border-radius: 4px;
    }
    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }
    .van-checkbox__icon--checked .van-icon {
      color: #fff !important;
      background-color: #004ea3;
      border-color: #004ea3;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
  .iconfont {
    color: #004ea3;
    font-size: 18px;
  }
}
</style>
